import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

const RootLayout = ({ children }) => {
  // Create an event handler so you can call the verification on button click event or form submit

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LdUV_YgAAAAACWfSlKPk59V86igsMS8mR2G0MxF"
      language="es-ES"
      // scriptProps={{
      //   async: false, // optional, default to false,
      //   defer: false, // optional, default to false
      //   appendTo: "head", // optional, default to "head", can be "head" or "body",
      //   nonce: undefined, // optional, default undefined
      // }}
      container={{
        // optional to render inside custom element
        element: "recaptcha-container",
        parameters: {
          badge: "bottomleft", // optional, default undefined
          // theme: "dark", // optional, default undefined
        },
      }}
    >
      {children}
      <div id="recaptcha-container" />
    </GoogleReCaptchaProvider>
  )
}

export default RootLayout
