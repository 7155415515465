import React from "react"
import RootLayout from "./src/components/common/RootLayout"

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"
export const wrapRootElement = ({ element }) => (
  <RootLayout>{element}</RootLayout>
)
